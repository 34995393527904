import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { isMobile } from 'react-device-detect';
import { WebSocketProvider } from '@/providers/WebSocketProvider';

// ----------------------------------------------------------------------

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
  const { pathname } = useRouter();
  const isHome = pathname === '/';

  return (
    <WebSocketProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
        <Header />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ...(!isHome && {
              pt: { xs: 8, md: 11 },
            }),
          }}
        >
          {children}
        </Box>

        {!isMobile && <Footer />}
      </Box>
    </WebSocketProvider>
  );
}
